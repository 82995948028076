import React from 'react';
import ReactDOM from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppProvider } from './AppContext';
import reportWebVitals from './reportWebVitals';
import './index.css';

const domain = "dev-57qkqgjpw47qb3zq.us.auth0.com";
const clientId = "QLRTsv6Qlzqqg80XX8nyepQVazQyQbuE";

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    authorizationParams={{
      redirect_uri: window.location.origin,
    }}
  >
    <AppProvider>
      <React.StrictMode>
        <Router>
          <App />
        </Router>
      </React.StrictMode>
    </AppProvider>
  </Auth0Provider>
);

reportWebVitals();
