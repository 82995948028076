import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, FormControlLabel, Switch, Typography } from '@mui/material';

interface PermissionsToggleProps {
  roleId: number;
}

const RolePermissionsToggle: React.FC<PermissionsToggleProps> = ({ roleId }) => {
  const [permissions, setPermissions] = useState<Record<string, boolean>>({});

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/roles/${roleId}/permissions`);
        const permsArray: string[] = response.data.permissions;
        const permsObj: Record<string, boolean> = {};
        const allPerms = [
          'ViewCollisionRiskDashboard',
          'ViewExceptionsDashboard',
          'ViewDeviceStatusTable',
          'ViewUsersTable',
          'ViewCustomerConfiguration',
        ];
        allPerms.forEach((p) => (permsObj[p] = false));
        permsArray.forEach((p) => (permsObj[p] = true));
        setPermissions(permsObj);
      } catch (error) {
        console.error('Error fetching permissions:', error);
      }
    };
    fetchPermissions();
  }, [roleId]);

  const handleToggle = async (perm: string, value: boolean) => {
    const updatedPermissions = { ...permissions, [perm]: value };
    setPermissions(updatedPermissions);
    const activePerms = Object.keys(updatedPermissions).filter((p) => updatedPermissions[p]);
    await axios.put(`${process.env.REACT_APP_API_URL}/api/roles/${roleId}/permissions`, {
      permissions: activePerms,
    });
  };

  return (
    <Box
      sx={{
        maxWidth: 500,
        margin: '0 auto',
        padding: 2,
        textAlign: 'center',
        border: '1px solid #ccc',
        borderRadius: 4,
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Typography variant="h6" sx={{ fontSize: '1rem', marginBottom: 1 }}>
        Member Access
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr',
          gap: 1,
          justifyContent: 'center',
        }}
      >
        {Object.keys(permissions).map((perm) => (
          <FormControlLabel
            key={perm}
            control={
              <Switch
                checked={permissions[perm]}
                onChange={(e) => handleToggle(perm, e.target.checked)}
                size="small"
              />
            }
            label={perm.replace(/View/, '').replace(/([A-Z])/g, ' $1').trim()}
            sx={{ fontSize: '0.9rem', margin: 0 }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default RolePermissionsToggle;
