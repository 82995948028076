// utils.tx
export type Order = 'asc' | 'desc';

// Function to compare two items in descending order
export function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
  const aValue = a[orderBy];
  const bValue = b[orderBy];

  if (typeof aValue === 'number' && typeof bValue === 'number') {
    return bValue - aValue;
  }

  if (typeof aValue === 'string' && typeof bValue === 'string') {
    return bValue.localeCompare(aValue);
  }

  // If types are different or not comparable, consider them equal
  return 0;
}

// Function to get the comparator based on the order
export function getComparator<T>(
  order: Order,
  orderBy: keyof T
): (a: T, b: T) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator<T>(a, b, orderBy)
    : (a, b) => -descendingComparator<T>(a, b, orderBy);
}

export function getComparatorByExtractor<T>(
  order: Order,
  valueExtractor: (item: T) => string | number
): (a: T, b: T) => number {
  return order === 'desc'
    ? (a, b) => descendingComparatorByExtractor(a, b, valueExtractor)
    : (a, b) => -descendingComparatorByExtractor(a, b, valueExtractor);
}

export function descendingComparatorByExtractor<T>(
  a: T,
  b: T,
  valueExtractor: (item: T) => string | number
): number {
  const aValue = valueExtractor(a);
  const bValue = valueExtractor(b);

  if (typeof aValue === 'number' && typeof bValue === 'number') {
    return bValue - aValue;
  }

  if (typeof aValue === 'string' && typeof bValue === 'string') {
    return bValue.localeCompare(aValue);
  }

  return 0;
}
