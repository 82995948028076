import React, { useContext, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { AppContext } from './AppContext';

interface RequireAuthProps {
  permission?: string;
  children: React.ReactNode;
}

const RequireAuth: React.FC<RequireAuthProps> = ({ permission, children }) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const { user } = useContext(AppContext);

  // If not authenticated, redirect to Auth0 login
  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  // Show loading spinner while checking authentication or user data
  if (isLoading || (!isAuthenticated && !isLoading)) {
    return <div>Loading...</div>;
  }

  // If authenticated but user data isn't loaded yet, wait
  if (isAuthenticated && !user) {
    return <div>Loading user data...</div>;
  }

  // Check for required permission
  if (permission && (!user || !user.permissions?.includes(permission))) {
    return <div>Access Denied</div>;
  }

  return <>{children}</>;
};

export default RequireAuth;
