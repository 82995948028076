import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableSortLabel,
  TableContainer,
  TablePagination,
  Paper,
  Typography,
  Tooltip,
  Box,
  LinearProgress,
  Checkbox,
  IconButton,
  CircularProgress,
} from '@mui/material';
import * as XLSX from 'xlsx';

import { green, red } from '../types';
import { FaRegFileExcel } from 'react-icons/fa';
import { AppContext } from '../../AppContext';

interface Customer {
  id: string;
  name: string;
  collisionRisk: number | null;
  averageCollisionRisk?: number | null;
  riskRelativeToHanover: number | null;
  engagementScore: number;
  safetyScore: number;
  generalScore: number;
  reportingScore: number;
  maintenanceScore: number;
  productivityScore: number;
  additionalSolutionsScore: number;
}

export interface FleetData {
  id: string;
  fleetName: string;
  collisionRisk: number | null;
  benchmarkRisk: number;
  riskRelativeToHanover: number | null;
  engagementScore: number;
  safetyScore: number;
  generalScore: number;
  reportingScore: number;
  maintenanceScore: number;
  productivityScore: number;
  additionalSolutionsScore: number;
}

interface CollisionRiskTableProps {
  selectedFleetId: string | null;
  onFleetSelect: (fleetId: string) => void;
  selectedCustomerIds: string[];
  onCheckboxChange: (customerId: string) => void;
  maxSelectedCustomers: number;
  selectedCustomerCount: number;
}

type Order = 'asc' | 'desc';
type SortableKeys = 'fleetName' | 'collisionRisk' | 'riskRelativeToHanover' | 'engagementScore';

const CollisionRiskTable: React.FC<CollisionRiskTableProps> = ({
  selectedFleetId,
  onFleetSelect,
  selectedCustomerIds,
  onCheckboxChange,
  maxSelectedCustomers,
  selectedCustomerCount,
}) => {
  const [fleetData, setFleetData] = useState<FleetData[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isLoading, setIsLoading] = useState(false);
  const { user: appUser } = useContext(AppContext);

  // Sorting
  const [sortBy, setSortBy] = useState<SortableKeys>('fleetName');
  const [sortOrder, setSortOrder] = useState<Order>('asc');

  useEffect(() => {
    if (!appUser?.region_id) { return; }
    const fetchMostRecentCollisionRisks = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/customers/most_recent_collision_risks`,
          {
            params: {
              page,
              page_size: pageSize,
              sort_by: sortBy,
              sort_order: sortOrder,
              region_id: appUser?.region_id
            },
          }
        );

        const fetchedCustomers: Customer[] = response.data.data.map((customer: any) => ({
          ...customer,
          id: customer.id.toString(),
          safetyScore: customer.safetyScore,
          generalScore: customer.generalScore,
          reportingScore: customer.reportingScore,
          maintenanceScore: customer.maintenanceScore,
          productivityScore: customer.productivityScore,
          additionalSolutionsScore: customer.additionalSolutionsScore,
        }));

        console.log(response.data);
        const fleetData = prepareFleetData(fetchedCustomers);
        setFleetData(fleetData);
        setTotalCount(response.data.total_count);
      } catch (error) {
        console.error('Error fetching most recent collision risks:', error);
      }
    };

    fetchMostRecentCollisionRisks();
  }, [page, pageSize, sortBy, sortOrder, appUser?.region_id]);

  // Helper function to build FleetData
  const prepareFleetData = (customers: Customer[]): FleetData[] => {
    if (customers.length === 0) return [];
    return customers.map((customer) => ({
      id: customer.id.toString(),
      fleetName: customer.name,
      collisionRisk: customer.collisionRisk ?? null,
      benchmarkRisk: customer.averageCollisionRisk ?? 0,
      riskRelativeToHanover: customer.riskRelativeToHanover ?? null,
      engagementScore: customer.engagementScore,
      safetyScore: customer.safetyScore,
      generalScore: customer.generalScore,
      reportingScore: customer.reportingScore,
      maintenanceScore: customer.maintenanceScore,
      productivityScore: customer.productivityScore,
      additionalSolutionsScore: customer.additionalSolutionsScore,
    }));
  };

  // Column sorting
  const handleRequestSort = (property: SortableKeys) => {
    const isAsc = sortBy === property && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setSortBy(property);
    setPage(1);
  };

  // Pagination
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(1);
  };

  // For coloring
  const getColorByComparison = (value: number | null, benchmark: number) => {
    if (value == null) return 'inherit';
    return value < benchmark ? green : red;
  };

  // ===========================================================
  // XLSX Export Logic
  // ===========================================================
  const handleDownloadExcel = async () => {
    try {
      setIsLoading(true);
      // 1) Fetch all collision data (ignoring pagination)
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/customers/most_recent_collision_risks`,
        { params: { all: true } }
      );
      const allCollisionData = res.data.data;

      // 2) Build "Collision Risks" sheet
      const collisionRows = allCollisionData.map((cust: any) => ({
        'Fleet Name': cust.name,
        'Collision Risk': cust.collisionRisk,
        'Risk Relative': cust.riskRelativeToHanover,
        'Engagement Score': cust.engagementScore,
      }));
      const collisionSheet = XLSX.utils.json_to_sheet(collisionRows);

      // 3) Fetch Engagement Scores from /customers/engagement_scores
      let engagementSheet;
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/customers/engagement_scores`);
        const allEngagementData = response.data;

        // Convert them into a row format, matching the keys returned
        const engagementRows = allEngagementData.map((eng: any) => ({
          'Customer ID': eng.customerId,
          'Customer Name': eng.customerName,
          'Engagement Score': eng.engagementScore,
          'Safety Score': eng.safetyScore,
          'General Score': eng.generalScore,
          'Reporting Score': eng.reportingScore,
          'Maintenance Score': eng.maintenanceScore,
          'Productivity Score': eng.productivityScore,
          'Additional Solutions Score': eng.additionalSolutionsScore,
          'Date': eng.calculatedAt,
        }));
        engagementSheet = XLSX.utils.json_to_sheet(engagementRows);
      } catch (e) {
        console.warn('Could not fetch engagement scores (or not needed)', e);
        engagementSheet = null;
      }

      // 4) Create a workbook, append both sheets
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, collisionSheet, 'Collision Risks');

      if (engagementSheet) {
        XLSX.utils.book_append_sheet(workbook, engagementSheet, 'Engagement Scores');
      }

      // 5) Download Excel
      XLSX.writeFile(workbook, 'collision_risk_engagement_data.xlsx');
    } catch (error) {
      console.error('Error downloading Excel:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <TableContainer component={Paper} sx={{ marginTop: 4 }}>
      <Typography variant="h6" sx={{ padding: 2, display: 'flex', justifyContent: 'space-between' }}>
        Collision Risk Table

        {/* The "Download Excel" button */}
        <Tooltip title="Download Excel">
          <span>
            <IconButton onClick={handleDownloadExcel} disabled={isLoading}>
              {isLoading ? (
                <CircularProgress size={24} />
              ) : (
                <FaRegFileExcel size={24} color="#4CAF50" />
              )}
            </IconButton>
          </span>
        </Tooltip>
      </Typography>

      <Table>
        <TableHead>
          <TableRow>
            {/* Checkbox Header */}
            <TableCell padding="checkbox">
              <Typography variant="caption">{`Select (max ${maxSelectedCustomers})`}</Typography>
            </TableCell>

            {/* Fleet */}
            <TableCell sortDirection={sortBy === 'fleetName' ? sortOrder : false}>
              <TableSortLabel
                active={sortBy === 'fleetName'}
                direction={sortBy === 'fleetName' ? sortOrder : 'asc'}
                onClick={() => handleRequestSort('fleetName')}
              >
                Fleet
              </TableSortLabel>
            </TableCell>

            {/* Collision Risk */}
            <TableCell sortDirection={sortBy === 'collisionRisk' ? sortOrder : false}>
              <TableSortLabel
                active={sortBy === 'collisionRisk'}
                direction={sortBy === 'collisionRisk' ? sortOrder : 'asc'}
                onClick={() => handleRequestSort('collisionRisk')}
              >
                Collision Risk (next 100,000 miles)
              </TableSortLabel>
            </TableCell>

            {/* Risk Relative */}
            <TableCell sortDirection={sortBy === 'riskRelativeToHanover' ? sortOrder : false}>
              <TableSortLabel
                active={sortBy === 'riskRelativeToHanover'}
                direction={sortBy === 'riskRelativeToHanover' ? sortOrder : 'asc'}
                onClick={() => handleRequestSort('riskRelativeToHanover')}
              >
                Risk Relative to Other Fleets
              </TableSortLabel>
            </TableCell>

            {/* Engagement Score */}
            <TableCell sortDirection={sortBy === 'engagementScore' ? sortOrder : false}>
              <TableSortLabel
                active={sortBy === 'engagementScore'}
                direction={sortBy === 'engagementScore' ? sortOrder : 'asc'}
                onClick={() => handleRequestSort('engagementScore')}
              >
                Engagement Score
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {fleetData.map((fleet) => (
            <TableRow
              key={fleet.id}
              hover
              selected={fleet.id === selectedFleetId}
              sx={{ cursor: 'pointer' }}
              onClick={() => onFleetSelect(fleet.id)}
            >
              {/* Checkbox Cell */}
              <TableCell padding="checkbox">
                <Tooltip
                  title={
                    fleet.collisionRisk == null
                      ? 'No data available for this customer'
                      : !selectedCustomerIds.includes(fleet.id) &&
                        selectedCustomerCount >= maxSelectedCustomers
                        ? `You can select up to ${maxSelectedCustomers} customers`
                        : ''
                  }
                  placement="top"
                >
                  <span>
                    <Checkbox
                      checked={selectedCustomerIds.includes(fleet.id)}
                      onChange={() => onCheckboxChange(fleet.id)}
                      disabled={
                        (!selectedCustomerIds.includes(fleet.id) && selectedCustomerCount >= maxSelectedCustomers) ||
                        fleet.collisionRisk == null
                      }
                      inputProps={{ 'aria-label': `Select ${fleet.fleetName}` }}
                    />
                  </span>
                </Tooltip>
              </TableCell>

              {/* Fleet Name */}
              <TableCell>{fleet.fleetName}</TableCell>

              {/* Collision Risk */}
              <TableCell>
                <Typography variant="body2">
                  {fleet.collisionRisk != null ? `${fleet.collisionRisk.toFixed(2)}%` : 'No Data'}
                </Typography>
              </TableCell>

              {/* Risk Relative */}
              <TableCell>
                {fleet.riskRelativeToHanover != null ? (
                  <Tooltip
                    title={
                      fleet.benchmarkRisk != null
                        ? `Compared to average risk: ${fleet.benchmarkRisk.toFixed(2)}%`
                        : 'No benchmark data'
                    }
                    arrow
                    placement="top"
                  >
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{
                        color: getColorByComparison(fleet.collisionRisk, fleet.benchmarkRisk),
                        cursor: 'help',
                      }}
                    >
                      {`${fleet.riskRelativeToHanover >= 0 ? '+' : ''}${fleet.riskRelativeToHanover.toFixed(2)}%`}
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography variant="body2">No Data</Typography>
                )}
              </TableCell>

              {/* Engagement Score */}
              <TableCell>
                <Tooltip
                  title={
                    <React.Fragment>
                      <Typography variant="body2">Safety: {Math.round(fleet.safetyScore)}%</Typography>
                      <Typography variant="body2">General: {Math.round(fleet.generalScore)}%</Typography>
                      <Typography variant="body2">Reporting: {Math.round(fleet.reportingScore)}%</Typography>
                      <Typography variant="body2">Maintenance: {Math.round(fleet.maintenanceScore)}%</Typography>
                      <Typography variant="body2">Productivity: {Math.round(fleet.productivityScore)}%</Typography>
                      <Typography variant="body2">Additional Solutions: {Math.round(fleet.additionalSolutionsScore)}%</Typography>
                    </React.Fragment>
                  }
                  arrow
                  placement="top"
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ width: '100%', mr: 1 }}>
                      <LinearProgress variant="determinate" value={fleet.engagementScore} />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                      <Typography variant="body2" color="textSecondary">
                        {`${Math.round(fleet.engagementScore)}%`}
                      </Typography>
                    </Box>
                  </Box>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <TablePagination
        component="div"
        count={totalCount}
        page={page - 1}
        onPageChange={handleChangePage}
        rowsPerPage={pageSize}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[10, 25, 50]}
      />
    </TableContainer>
  );
};

export default CollisionRiskTable;
