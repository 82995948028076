// Updated CustomerDetailsModal.tsx
import React, { useEffect, useState, useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Box,
} from '@mui/material';
import ExcelDownloadButton from '../ExcelDownloadButton';
import axios from 'axios';
import { Device } from '../types';
import { DataRow } from './DeviceStatusTable';
import { getComparator, Order } from '../utils';

interface CustomerDetailsModalProps {
  customer: DataRow;
  isOpen: boolean;
  onClose: () => void;
  updateComments: (customerId: string, newComments: string) => void;
}

const CustomerDetailsModal: React.FC<CustomerDetailsModalProps> = ({
  customer,
  isOpen,
  onClose,
  updateComments,
}) => {
  const [comment, setComment] = useState<string>(customer.comments || '');
  const [deviceDetails, setDeviceDetails] = useState<Device[]>([]);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<keyof Device>('id');

  const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setComment(e.target.value);
  };

  const handleSave = () => {
    updateComments(customer.id, comment);
    onClose();
  };

  function daysSinceShipmentComparator(a: Device, b: Device, order: Order): number {
    // Always push installed devices (hyphen cases) to bottom
    if (a.isInstalled && !b.isInstalled) return 1;
    if (!a.isInstalled && b.isInstalled) return -1;

    // If both devices are installed, consider them equal
    if (a.isInstalled && b.isInstalled) return 0;

    // Use fallback values for daysSinceShipment if undefined
    const aDays = a.daysSinceShipment ?? 0;
    const bDays = b.daysSinceShipment ?? 0;

    const diff = aDays - bDays;
    return order === 'asc' ? diff : -diff;
  }

  const handleSort = (property: keyof Device) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedDevices = useMemo(() => {
    if (!orderBy) return deviceDetails;  // If no orderBy, skip sorting.

    let currentComparator: (a: Device, b: Device) => number;
    if (orderBy === 'daysSinceShipment') {
      currentComparator = (a, b) => daysSinceShipmentComparator(a, b, order);
    } else {
      // We can safely cast because we've ensured orderBy is truthy and valid.
      currentComparator = getComparator<Device>(order, orderBy as keyof Device);
    }

    return [...deviceDetails].sort(currentComparator);
  }, [deviceDetails, order, orderBy]);

  useEffect(() => {
    setComment(customer.comments || '');
  }, [customer]);

  useEffect(() => {
    const fetchDevices = async () => {
      if (!customer.id) return;

      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/customers/${customer.id}/devices`);
        const devices = response.data.map((device: any) => ({
          serialNumber: device.serial_number,
          shipmentDate: device.shipped_date,
          ratePlan: device.rate_plan,
          isInstalled: device.is_installed === 1,
          daysSinceShipment: device.days_since_shipment,
        }));
        setDeviceDetails(devices);
      } catch (error) {
        console.error('Error fetching devices:', error);
      }
    };

    fetchDevices();
  }, [customer]);

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Customer Details - {customer.customerName}</DialogTitle>
      <DialogContent dividers>
        {/* Device Details Section */}
        <Typography variant="h6" gutterBottom>
          Device Details
          <ExcelDownloadButton
            data={deviceDetails}
            filename={`${customer.customerName}_device_details.xlsx`}
          />
        </Typography>
        <Box
          sx={{
            maxHeight: 300,
            overflowY: 'auto',
            mb: 2,
          }}
        >
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sortDirection={orderBy === 'serialNumber' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'serialNumber'}
                    direction={orderBy === 'serialNumber' ? order : 'asc'}
                    onClick={() => handleSort('serialNumber')}
                  >
                    Serial Number
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={orderBy === 'shipmentDate' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'shipmentDate'}
                    direction={orderBy === 'shipmentDate' ? order : 'asc'}
                    onClick={() => handleSort('shipmentDate')}
                  >
                    Shipment Date
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={orderBy === 'ratePlan' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'ratePlan'}
                    direction={orderBy === 'ratePlan' ? order : 'asc'}
                    onClick={() => handleSort('ratePlan')}
                  >
                    Rate Plan
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={orderBy === 'isInstalled' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'isInstalled'}
                    direction={orderBy === 'isInstalled' ? order : 'asc'}
                    onClick={() => handleSort('isInstalled')}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={orderBy === 'daysSinceShipment' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'daysSinceShipment'}
                    direction={orderBy === 'daysSinceShipment' ? order : 'asc'}
                    onClick={() => handleSort('daysSinceShipment')}
                  >
                    Days Since Shipment
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedDevices.map((device, index) => (
                <TableRow key={index}>
                  <TableCell>{device.serialNumber}</TableCell>
                  <TableCell>{device.shipmentDate}</TableCell>
                  <TableCell>{device.ratePlan}</TableCell>
                  <TableCell>{device.isInstalled ? 'Installed' : 'Not Installed'}</TableCell>
                  <TableCell>
                    {!device.isInstalled ? device.daysSinceShipment : '-'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        {/* Comments Section */}
        <Typography variant="h6" gutterBottom>
          Account Comments
        </Typography>
        <TextField
          label="Comments"
          multiline
          rows={4}
          fullWidth
          variant="outlined"
          value={comment}
          onChange={handleCommentChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomerDetailsModal;
