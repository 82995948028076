// EditUserModal.tsx
import React, { useContext, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { Region, User } from '../types';
import { AppContext } from '../../AppContext';
import { useAuth0 } from '@auth0/auth0-react';

interface EditUserModalProps {
  open: boolean;
  onClose: () => void;
  user: User;
  onUpdateUser: () => void;
  regions: Region[];
}

const EditUserModal: React.FC<EditUserModalProps> = ({
  open,
  onClose,
  user,
  onUpdateUser,
  regions
}) => {
  const { isAuthenticated, user: auth0User, isLoading, getAccessTokenSilently } = useAuth0();
  const [email, setEmail] = useState<string>(user.email);
  const [password, setPassword] = useState<string>('');
  const [isPasswordEditable, setIsPasswordEditable] = useState<boolean>(false);
  const [roleId, setRoleId] = useState<string>(user.role_id.toString());
  const [regionId, setRegionId] = useState<string>(user.region_id || '');
  const { user: currentUser } = useContext(AppContext);

  const handlePasswordEditClick = () => {
    setIsPasswordEditable(true);
  };

  const handleSave = async () => {
    try {
      const updateData: any = {
        user_id: user.id,
        auth0_sub: user.auth0_sub,
        email,
        role_id: parseInt(roleId, 10),
        region_id: regionId,
        current_auth0_sub: auth0User?.sub
      };

      // Only include password if user actually edited it
      if (isPasswordEditable && password) {
        updateData.password = password;
      }

      onClose();
      await axios.put(`${process.env.REACT_APP_API_URL}/update-user`, updateData);

      onUpdateUser();
    } catch (error) {
      console.error('Failed to update user', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit User</DialogTitle>
      <DialogContent>
        {/* Editable Email */}
        <TextField
          margin="dense"
          label="Email"
          type="email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        {/* Editable Password (click pencil icon to enable) */}
        <TextField
          margin="dense"
          label="Password"
          type="password"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          disabled={!isPasswordEditable}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handlePasswordEditClick}>
                  <EditIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        {/* Role */}
        <TextField
          label="Role"
          fullWidth
          select
          value={roleId}
          onChange={(e) => setRoleId(e.target.value)}
          margin="dense"
        >
          {/* Only show SUPERADMIN if currentUserRole is 1 */}
          {currentUser ? currentUser.role_id <= 1 && <MenuItem value="1">SUPERADMIN</MenuItem> : <div/>}

          {/* Only show ADMIN if currentUserRole <= 2 */}
          {currentUser ? currentUser.role_id <= 2 && <MenuItem value="2">ADMIN</MenuItem> : <div/>}

          {/* Always show MEMBER, since it’s role=3 and is “lower” */}
          <MenuItem value="3">MEMBER</MenuItem>
        </TextField>

        {/* Region */}
        <TextField
          margin="dense"
          label="Region"
          fullWidth
          select
          value={regionId}
          onChange={(e) => setRegionId(e.target.value as string)}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {regions.map((region) => (
            <MenuItem key={region.id} value={region.id}>
              {region.name}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditUserModal;
