import React, { useMemo } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import ChartComponent from './ChartComponent';
import { Customer, DataPoint } from '../../types';

interface ChartsContainerProps {
  loading: boolean;
  data: DataPoint[];
  customers: Customer[];
  startDate: Date | null;
  endDate: Date | null;
  selectedCustomerIds: string[];
}

interface SelectedCustomersData {
  [key: string]: {
    name: string;
    data: DataPoint[];
  };
}

function buildCustomerDataMap(customers: Customer[]): SelectedCustomersData {
  const map: SelectedCustomersData = {};
  customers.forEach((cust) => {
    map[cust.id] = {
      name: cust.name,
      data: cust.data,
    };
  });
  return map;
}

const ChartsContainer: React.FC<ChartsContainerProps> = ({
  loading,
  data,
  customers,
  startDate,
  endDate,
  selectedCustomerIds,
}) => {
  const allCustomersData = useMemo(() => {
    return buildCustomerDataMap(customers);
  }, [customers]);

  const selectedCustomersData = useMemo(() => {
    const selected = customers.filter((c) => selectedCustomerIds.includes(c.id));
    return buildCustomerDataMap(selected);
  }, [customers, selectedCustomerIds]);

  if (!startDate || !endDate) {
    return <div>Please select a date range.</div>;
  }

  return (
    <div style={{ position: 'relative', minHeight: 400 }}>
      {loading && (
        <div
          style={{
            position: 'absolute',
            top: 0, left: 0, right: 0, bottom: 0,
            zIndex: 999,
            backgroundColor: 'rgba(255,255,255,0.7)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </div>
      )}

      <ChartComponent
        data={data}
        title="Collision Risk Over Time"
        startDate={startDate}
        endDate={endDate}
        allCustomersData={allCustomersData}
        selectedCustomersData={selectedCustomersData}
      />
    </div>
  );
};

export default ChartsContainer;
