import axios from "axios";


export default function getAllExceptions(api: any, daysFilter: string) {
    let seatbeltUnbuckledExceptions = getExceptions(api, "Speeding", daysFilter);
    let speedingExceptions: never[] = []//getExceptions("Speeding", daysFilter);
    let harshBrakingExceptions: never[] = []//getExceptions("Harsh Braking", daysFilter);
    let harshAccelerationExceptions: never[] = []//getExceptions("Harsh Acceleration", daysFilter);
    let harshCorneringExceptions: never[] = []//getExceptions("Harsh Cornering", daysFilter);
    return {
        seatbeltUnbuckled: seatbeltUnbuckledExceptions,
        speeding: speedingExceptions,
        harshBraking: harshBrakingExceptions,
        harshAcceleration: harshAccelerationExceptions,
        harshCornering: harshCorneringExceptions,
    };
}

export async function getAccounts() {
  const url = 'https://v4dzu86w87.execute-api.us-east-1.amazonaws.com/dev/account';
  const resp = await axios.get(url);
  return resp.data;
}

function getExceptions(api: any, ruleName: string, daysFilter: string) {
    let daysSplit: number;
    //split dates into weeks or months for each column
    daysSplit = daysFilter === '360' ? 30 : 7;

    return api.call("Get", {
        "typeName": "Rule",
        "search": { "name": ruleName }
    }, function(result: { id: any; }[]) {
        let ruleId = result[0].id;
        let toDate = new Date();
        let fromDate = new Date();
        fromDate.setDate(fromDate.getDate() - Number(daysFilter));
        api.call("Get", {
            "typeName": "ExceptionEvent",
            "search": {
                "ruleSearch": {
                    "id": ruleId
                },
                "fromDate": fromDate,
                "toDate": toDate
            }
        }, function(result: any) {
            let eventDates: any[][] = [];
            result.forEach((element: any) => {
                element.activeFrom = new Date(element.activeFrom);
            });
            let dayComparisonArray: Date[] = [];
            //loop and fill dayComparisonArray with dates split into the amount
            //that matches the number of columns to display
            switch (daysFilter) {
                case '360':
                    for (let i = 0; i < 12; i++) {
                        let day = new Date();
                        day.setDate(day.getDate() - (daysSplit * (i)));
                        dayComparisonArray.push(day);
                    }
                    break;
                case '90':
                    for (let i = 0; i < 13; i++) {
                        let day = new Date();
                        day.setDate(day.getDate() - (daysSplit * (i)));
                        dayComparisonArray.push(day);
                    }
                    break;
                case '30':
                    for (let i = 0; i < 4; i++) {
                        let day = new Date();
                        day.setDate(day.getDate() - (daysSplit * (i)));
                        dayComparisonArray.push(day);
                    }
                    break;
                case 'Custom':
            }
            //loop through results and then loop through dayComparisonArray
            //to fill eventDates array with exception events split for each column
            //that needs to be displayed
            dayComparisonArray.reverse();
            result.forEach((element: any) => {
                dayComparisonArray.some((dayComparison, key) => {
                    if (element.activeFrom < dayComparison) {
                        if (eventDates[key]) {
                            eventDates[key].push(element);
                        } else {
                            eventDates[key] = [element];
                        }
                        return true;
                    }
                })
            });


            //multicall to get odometer value pairs for each
            dayComparisonArray.forEach(element => {
                let fromDateTest = new Date(element);
                fromDateTest.setDate(fromDateTest.getDate() - 1);
            });
            let odometerCalls: any[] = [];
            let odometerData: any = {};
            return api.call("Get", { "typeName": "Device" },
                function(results: any) {
                    let deviceIds = results.map((element: { id: any; }) => {
                        return element.id
                    });
                    let totalColumns = daysFilter === '360' ? 12
                        : daysFilter === '90' ? 13
                            : 4;
                    let totalLoops = totalColumns + 1;
                    let returnObject: any[] = [];
                    for (let i = 0; i < totalLoops; i++) {
                        let odometerFromDate = new Date();
                        odometerFromDate.setDate(odometerFromDate.getDate() - ((totalLoops - i) * daysSplit));
                        odometerCalls = [];
                        deviceIds.forEach((id: string) => {
                            odometerCalls.push(["Get", {
                                "typeName": "StatusData",
                                "search": {
                                    "deviceSearch": {
                                        "id": id
                                    },
                                    "diagnosticSearch": {
                                        "id": "DiagnosticOdometerId"
                                    },
                                    "fromDate": odometerFromDate,
                                    "toDate": odometerFromDate
                                }
                            }])
                        });
                        api.multiCall(odometerCalls, function(multicallData: any) {
                            multicallData.forEach((odometerValue: any) => {
                                if (!odometerValue[0]) {
                                    return;
                                }
                                if (!odometerData[odometerValue[0].device.id]) {
                                    odometerData[odometerValue[0].device.id] = {};
                                }
                                odometerData[odometerValue[0].device.id][i] = {
                                    odometer: Math.trunc(odometerValue[0].data / 1609.344),
                                    datetime: odometerValue[0].dateTime
                                };
                            });
                            let dataLength = Object.keys(odometerData[
                                Object.keys(odometerData)[
                                Object.keys(odometerData).length - 1]]).length
                            if (dataLength === totalLoops) {
                                returnObject.push(odometerData);
                            }
                        });
                    }
                    return returnObject;
                }
            );
        }, function(e: any) {
            console.log(e);
        });


    }, function(e: any) {
        console.error("Failed2:", e);
    });
}


/**
 * Make an api request to the Auth0 API to get all users
 * @return [] an array of objects, each with the properties name, email, and user_id
 */
export async function getUsers(axiosBaseConfig: any) {
    try {
        let results = await axios.get('/users?fields=given_name,family_name,email,user_id', axiosBaseConfig);
        return results.data;
    }
    catch (error: any) {
        console.error(error);
    }
}
