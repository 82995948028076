import React, { useState } from 'react';
import { IconButton, Tooltip, CircularProgress } from '@mui/material';
import * as XLSX from 'xlsx';
import { FaRegFileExcel } from 'react-icons/fa';

interface ExcelDownloadButtonProps {
  data: any[];
  filename?: string;
  /**
   * Optional callback that runs BEFORE generating/downloading XLSX.
   * Typically used to fetch "all data" or do other async tasks.
   */
  onBeforeDownload?: () => Promise<void>;
}

/**
 * Similar to CsvDownloadButton, but uses xlsx to produce an Excel file.
 * We show a spinner while `onBeforeDownload` is in progress.
 */
const ExcelDownloadButton: React.FC<ExcelDownloadButtonProps> = ({
  data,
  filename = 'data.xlsx',
  onBeforeDownload,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDownload = async () => {
    // 1) If there's a callback, invoke it first
    if (onBeforeDownload) {
      setIsLoading(true);
      try {
        await onBeforeDownload();  // e.g. fetch "all" data
      } catch (error) {
        console.error('Error in onBeforeDownload:', error);
        setIsLoading(false);
        return; // stop if fetching fails
      }
      setIsLoading(false);
    }

    // 2) Check if data is empty
    if (!data || data.length === 0) {
      alert('No data available to download.');
      return;
    }

    // 3) Build a worksheet from `data`
    const worksheet = XLSX.utils.json_to_sheet(data);

    // 4) Build a new workbook and append the sheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');

    // 5) Trigger download of the .xlsx file
    XLSX.writeFile(workbook, filename);
  };

  return (
    <Tooltip title="Download Excel">
      <span>
        <IconButton onClick={handleDownload} disabled={isLoading}>
          {isLoading ? (
            <CircularProgress size={24} />
          ) : (
            <FaRegFileExcel size={24} color="#4CAF50" />
          )}
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default ExcelDownloadButton;
