// AuthSync.tsx
import { useEffect, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { AppContext } from './AppContext';

const AuthSync: React.FC = () => {
  const { isAuthenticated, user: auth0User, isLoading, getAccessTokenSilently } = useAuth0();
  const { setUser } = useContext(AppContext);

  useEffect(() => {
    const syncUser = async () => {
      if (isAuthenticated && auth0User) {
        try {
          const token = await getAccessTokenSilently();

          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/sync-user`,
            {
              email: auth0User.email,
              auth0_sub: auth0User.sub,
            },
            {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
              },
            }
          );

          const userData = response.data;
          setUser({
            id: userData.id,
            email: userData.email,
            role_id: userData.role_id,
            region_id: userData.region_id,
            permissions: userData.permissions,
          });
        } catch (error: any) {
          console.error("Failed to sync user", error);
        }
      }
    };

    syncUser();
  }, [isAuthenticated, auth0User, isLoading, getAccessTokenSilently, setUser]);

  return null;
};

export default AuthSync;
