import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { getComparatorByExtractor, Order } from '../utils';
import { Customer, Region } from '../types';
import RolePermissionsToggle from './RolePermissionsToggle';

const CustomerConfiguration: React.FC = () => {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [regions, setRegions] = useState<Region[]>([]);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<'name' | 'regionName'>('name');

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const [regionsResponse, customersResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_URL}/regions`),
          axios.get(`${process.env.REACT_APP_API_URL}/customers`),
        ]);
        setRegions(regionsResponse.data as Region[]);
        setCustomers(customersResponse.data as Customer[]);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchAllData();
  }, []);

  const handleRegionChange = async (
    event: SelectChangeEvent<string>,
    customerId: string
  ) => {
    const newRegionId = event.target.value;
    const selectedRegion = regions.find((region) => region.id === newRegionId) || null;

    setCustomers((prevCustomers) =>
      prevCustomers.map((customer) =>
        customer.id === customerId
          ? { ...customer, region_id: newRegionId, region: selectedRegion }
          : customer
      )
    );

    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/customers/${customerId}/region`, {
        region_id: newRegionId,
      });
    } catch (error) {
      console.error('Error updating customer region:', error);
    }
  };

  const handleRequestSort = (property: 'name' | 'regionName') => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedCustomers = React.useMemo(() => {
    return [...customers].sort(
      getComparatorByExtractor<Customer>(
        order,
        orderBy === 'name'
          ? (customer) => customer.name
          : (customer) => customer.region?.name || ''
      )
    );
  }, [customers, order, orderBy]);

  return (
    <Container
      maxWidth="lg"
      sx={{
        padding: 2,
        margin: '0 auto',
      }}
    >
      <Typography variant="h4" gutterBottom align="center">
        Customer Configuration
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          gap: 6, // Increased gap for spacing
          marginTop: 2,
        }}
      >
        {/* Member Access Section */}
        <Box
          sx={{
            maxWidth: 350,
            flexShrink: 0,
            border: 'none',
          }}
        >
          <RolePermissionsToggle roleId={3} />
        </Box>

        {/* Customer Configuration Table */}
        <Box
          sx={{
            flexGrow: 1,
            maxWidth: 800,
          }}
        >
          <TableContainer
            sx={{
              maxHeight: '400px',
              overflowY: 'auto',
              border: '1px solid #e0e0e0',
              borderRadius: '0px 0px 4px 4px',
            }}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sortDirection={orderBy === 'name' ? order : false}>
                    <TableSortLabel
                      active={orderBy === 'name'}
                      direction={orderBy === 'name' ? order : 'asc'}
                      onClick={() => handleRequestSort('name')}
                    >
                      Customer Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align="center"
                    sortDirection={orderBy === 'regionName' ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === 'regionName'}
                      direction={orderBy === 'regionName' ? order : 'asc'}
                      onClick={() => handleRequestSort('regionName')}
                    >
                      Region
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedCustomers.map((customer) => (
                  <TableRow key={customer.id}>
                    <TableCell sx={{ padding: '4px 8px' }}>
                      {customer.name}
                    </TableCell>
                    <TableCell sx={{ padding: '4px 8px' }}>
                      <FormControl variant="standard" fullWidth>
                        <Select
                          value={customer.region_id || ''}
                          onChange={(event) =>
                            handleRegionChange(event, customer.id)
                          }
                          displayEmpty
                          sx={{
                            fontSize: '0.9rem',
                            width: '10rem',
                          }}
                        >
                          {regions.map((region) => (
                            <MenuItem key={region.id} value={region.id}>
                              {region.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Container>
  );
};

export default CustomerConfiguration;
