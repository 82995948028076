import React, { useState, useEffect, useContext } from 'react';
import { Calendar, DayRange, DayValue } from '@hassanmojab/react-modern-calendar-datepicker';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import {
  Toolbar,
  Container,
  FormControl,
  InputLabel,
  Select,
  Checkbox,
  Typography,
  Box,
  IconButton,
  Popover,
  Autocomplete,
  Chip,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { format } from 'date-fns';
import axios from 'axios';

// Icons for checkboxes in the Autocomplete
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import CollisionRiskTable from './CollisionRiskTable';
import ChartsContainer from './Charts/ChartsContainer';
import { Customer, DataPoint } from '../types';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { AppContext } from '../../AppContext';

const MAX_SELECTED_CUSTOMERS = 3;

// Utility to generate fleet data
const generateHanoverFleetsData = (customers: Customer[]): DataPoint[] => {
  if (customers.length === 0) return [];

  const dateSet = new Set<number>();
  customers.forEach((customer) => {
    customer.data.forEach((d) => dateSet.add(d.date));
  });
  const dates = Array.from(dateSet).sort((a, b) => a - b);

  let lastBenchmarkRisk: number | null = null;
  const hanoverFleetsData: DataPoint[] = [];

  dates.forEach((date) => {
    let totalDevices = 0;
    let totalWeightedCollisionRisk = 0;

    customers.forEach((customer) => {
      const dataPoint = customer.data.find((d) => d.date === date);
      const numDevices = customer.numActiveDevices || 0;
      if (numDevices > 0 && dataPoint) {
        if (dataPoint.collisionRisk != null && dataPoint.collisionRisk !== 0) {
          totalDevices += numDevices;
          totalWeightedCollisionRisk += dataPoint.collisionRisk * numDevices;
        }
      }
    });

    let avgCollisionRisk: number | null = null;
    if (totalDevices > 0) {
      avgCollisionRisk = totalWeightedCollisionRisk / totalDevices;
    }

    let avgBenchmarkRisk: number | null = null;
    if (lastBenchmarkRisk != null) {
      avgBenchmarkRisk = lastBenchmarkRisk;
    }

    if (avgCollisionRisk != null || avgBenchmarkRisk != null) {
      hanoverFleetsData.push({
        date,
        collisionRisk:
          avgCollisionRisk != null
            ? parseFloat(avgCollisionRisk.toFixed(1))
            : (null as unknown as number),
        benchmarkRisk:
          avgBenchmarkRisk != null
            ? parseFloat(avgBenchmarkRisk.toFixed(1))
            : (null as unknown as number),
      });
    }
  });

  return hanoverFleetsData;
};

// same date helper as before
const formatDate = (day: DayValue): string => {
  if (!day) return '';
  const date = new Date(day.year, day.month - 1, day.day);
  return format(date, 'MMM dd, yyyy');
};

const CollisionRiskDashboard: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { user: appUser } = useContext(AppContext);
  const [allCustomers, setAllCustomers] = useState<Customer[]>([]);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [selectedCustomers, setSelectedCustomers] = useState<Customer[]>([]);

  const [hanoverFleetsData, setHanoverFleetsData] = useState<DataPoint[]>([]);

  const [dateRangeOption, setDateRangeOption] = useState<
    '30days' | '6months' | 'year' | 'all' | 'custom'
  >('30days');

  const [customDateRange, setCustomDateRange] = useState<DayRange>({
    from: null,
    to: null,
  });

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [selectedFleetId, setSelectedFleetId] = useState<string | null>(null);

  const [loading, setLoading] = useState(false);

  // Icons for checkboxes in the Autocomplete
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  // fleet selection
  const handleFleetSelect = (fleetId: string) => {
    setSelectedFleetId(fleetId);
  };

  // convert day -> Date
  const convertToDate = (day: DayValue, isEndDate: boolean = false): Date | null => {
    if (!day) return null;
    const date = new Date(day.year, day.month - 1, day.day);
    if (isEndDate) {
      date.setHours(23, 59, 59, 999);
    }
    return date;
  };

  // fetch all
  useEffect(() => {
    const fetchAllCustomers = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/customers`);
        let customers: Customer[] = response.data.map((c: any) => ({
          ...c,
          id: c.id.toString(),
        }));

        if (appUser?.region_id && appUser.region_id !== 1) {
          customers = customers.filter(
            (c) => c.region_id === appUser.region_id!.toString()
          );
        }
        setAllCustomers(customers);
      } catch (error) {
        console.error('Error fetching customers:', error);
      }
    };
    fetchAllCustomers();
  }, [appUser?.region_id]);

  // fetch collision risk data
  useEffect(() => {
    if (!startDate || !endDate) return;

    setLoading(true);

    const fetchData = async () => {
      try {
        const params = {
          start_date: startDate.toISOString().split('T')[0],
          end_date: endDate.toISOString().split('T')[0],
        };
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/collision_risks`, {
          params,
        });
        const fetchedCustomers: Customer[] = response.data.map((c: any) => ({
          ...c,
          id: c.id.toString(),
        }));

        // sort data descending
        fetchedCustomers.forEach((cust) => {
          if (cust.data.length > 0) {
            cust.data.sort((a, b) => b.date - a.date);
            cust.collisionRisk = cust.data[0].collisionRisk;
          }
        });
        setCustomers(fetchedCustomers);
        // reset fleets so chart won't block
        setHanoverFleetsData([]);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [startDate, endDate]);

  // generate fleets data after customers are loaded
  useEffect(() => {
    if (customers.length === 0) return;
    const asyncCalcHanoverData = async () => {
      const hanoverData = generateHanoverFleetsData(customers);
      setHanoverFleetsData(hanoverData);
    };
    asyncCalcHanoverData();
  }, [customers]);

  // update start/end based on dateRangeOption
  useEffect(() => {
    const now = new Date();
    let newStartDate: Date;
    let newEndDate: Date;

    if (dateRangeOption === 'custom') {
      if (customDateRange.from && customDateRange.to) {
        const fromDate = convertToDate(customDateRange.from);
        const toDate = convertToDate(customDateRange.to, true);
        if (fromDate && toDate) {
          newStartDate = fromDate;
          newEndDate = toDate;
          setStartDate(newStartDate);
          setEndDate(newEndDate);
        }
      }
      return;
    }

    switch (dateRangeOption) {
      case '30days':
        newStartDate = new Date(now.getTime() - 29 * 24 * 60 * 60 * 1000);
        newStartDate.setHours(0, 0, 0, 0);
        newEndDate = new Date();
        newEndDate.setHours(23, 59, 59, 999);
        break;
      case '6months':
        newStartDate = new Date(now);
        newStartDate.setMonth(now.getMonth() - 5);
        newStartDate.setHours(0, 0, 0, 0);
        newEndDate = new Date();
        newEndDate.setHours(23, 59, 59, 999);
        break;
      case 'year':
        newStartDate = new Date(now);
        newStartDate.setFullYear(now.getFullYear() - 1);
        newStartDate.setDate(newStartDate.getDate() + 1);
        newStartDate.setHours(0, 0, 0, 0);
        newEndDate = new Date();
        newEndDate.setHours(23, 59, 59, 999);
        break;
      case 'all':
        newStartDate = new Date('2020-01-01');
        newStartDate.setHours(0, 0, 0, 0);
        newEndDate = now;
        newEndDate.setHours(23, 59, 59, 999);
        break;
      default:
        newStartDate = new Date('2020-01-01');
        newStartDate.setHours(0, 0, 0, 0);
        newEndDate = now;
        newEndDate.setHours(23, 59, 59, 999);
        break;
    }

    setStartDate(newStartDate);
    setEndDate(newEndDate);
  }, [dateRangeOption, customDateRange]);

  // checkbox change
  const handleCheckboxChange = (customerId: string) => {
    const matchingCustomer = customers.find((c) => c.id === customerId);
    if (!matchingCustomer) return;

    setSelectedCustomers((prevSelected) => {
      const isAlreadySelected = prevSelected.some((c) => c.id === matchingCustomer.id);
      if (isAlreadySelected) {
        return prevSelected.filter((c) => c.id !== matchingCustomer.id);
      } else if (prevSelected.length < MAX_SELECTED_CUSTOMERS) {
        return [...prevSelected, matchingCustomer];
      } else {
        return prevSelected;
      }
    });
  };

  return (
    <>
      <Toolbar />
      <Container maxWidth="lg">
        {/* Dropdowns */}
        <Box sx={{ marginTop: 2 }}>
          {/* 1) Top row for all 3 dropdowns side-by-side */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            {/* Autocomplete */}
            <Autocomplete
              multiple
              disableCloseOnSelect
              options={allCustomers}
              value={selectedCustomers}
              onChange={(event, newValue) => {
                if (newValue.length <= MAX_SELECTED_CUSTOMERS) {
                  setSelectedCustomers(newValue);
                }
              }}
              getOptionLabel={(option) => option.name}
              renderTags={() => null}
              renderOption={(props, option, { selected }) => {
                const isDisabled = !selected && selectedCustomers.length >= MAX_SELECTED_CUSTOMERS;
                return (
                  <Tooltip
                    title={isDisabled ? `Max ${MAX_SELECTED_CUSTOMERS} customers selected` : ''}
                    placement="right"
                  >
                    <li
                      {...props}
                      style={{
                        pointerEvents: isDisabled ? 'none' : 'auto',
                        opacity: isDisabled ? 0.5 : 1,
                      }}
                    >
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        disabled={isDisabled}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  </Tooltip>
                );
              }}
              renderInput={(params) => (
                <TextField {...params} label="Search customers" placeholder="Type to search..." />
              )}
              sx={{ width: 300 }}
            />

            {/* Date Range Dropdown */}
            <FormControl variant="outlined" sx={{ minWidth: 200, marginRight: 2 }}>
              <InputLabel>Date Range</InputLabel>
              <Select
                value={dateRangeOption}
                onChange={(e) => {
                  const value = e.target.value as '30days' | '6months' | 'year' | 'all' | 'custom';
                  setDateRangeOption(value);
                }}
                label="Date Range"
              >
                <MenuItem value="30days">Previous 30 Days</MenuItem>
                <MenuItem value="6months">Previous 6 Months</MenuItem>
                <MenuItem value="year">Previous Year</MenuItem>
                <MenuItem value="all">All Time</MenuItem>
                <MenuItem value="custom">Custom</MenuItem>
              </Select>
            </FormControl>

            {/* Custom Date Range Picker */}
            {dateRangeOption === 'custom' && (
              <Box sx={{ marginLeft: 2 }}>
                <IconButton
                  aria-label="Select date range"
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                  }}
                >
                  <CalendarTodayIcon />
                  {dateRangeOption === 'custom' && customDateRange.from && customDateRange.to && (
                    <Typography sx={{ marginLeft: 1 }}>
                      {formatDate(customDateRange.from)} - {formatDate(customDateRange.to)}
                    </Typography>
                  )}
                </IconButton>
                <Popover
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={() => setAnchorEl(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <Box sx={{ p: 2 }}>
                    <Calendar
                      value={customDateRange}
                      onChange={(range) => {
                        setCustomDateRange(range);
                        if (range.from && range.to) {
                          setAnchorEl(null);
                        }
                      }}
                      colorPrimary="#0fbcf9"
                      colorPrimaryLight="#d1ecf1"
                      shouldHighlightWeekends
                    />
                  </Box>
                </Popover>
              </Box>
            )}
          </Box>

          {/* Second “row”: chips only */}
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 1 }}>
            {selectedCustomers.map((cust) => (
              <Chip
                key={cust.id}
                label={cust.name}
                onDelete={() =>
                  setSelectedCustomers((prev) => prev.filter((c) => c.id !== cust.id))
                }
              />
            ))}
          </Box>
        </Box>

        {/* Spacing between dropdowns and charts */}
        <Box sx={{ mt: 2 }}>
          {/* Pass loading to ChartsContainer */}
          <ChartsContainer
            loading={loading}
            data={hanoverFleetsData}
            customers={customers}
            startDate={startDate}
            endDate={endDate}
            selectedCustomerIds={selectedCustomers.map((c) => c.id.toString())}
          />
        </Box>

        {/* Collision Risk Table */}
        <CollisionRiskTable
          selectedFleetId={selectedFleetId}
          onFleetSelect={handleFleetSelect}
          selectedCustomerIds={selectedCustomers.map((c) => c.id.toString())}
          maxSelectedCustomers={MAX_SELECTED_CUSTOMERS}
          selectedCustomerCount={selectedCustomers.length}
          onCheckboxChange={handleCheckboxChange}
        />
      </Container>
    </>
  );
};

export default CollisionRiskDashboard;
