import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  TableSortLabel,
  Container,
  Toolbar,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import CustomerDetailsModal from './CustomerDetailsModal';
import { getComparator, Order } from '../utils';
import axios from 'axios';
import ExcelDownloadButton from '../ExcelDownloadButton';

// Define the data structure for each row
export interface DataRow {
  id: string;
  customerName: string;
  numberOfDevicesInGeotab: number;
  numberOfActiveDevices: number;
  numberOfNotActiveDevices: number;
  numOfDevicesInMyAdmin: number;
  percentageOfTotalDevicesInstalled: string;
  comments: string;
}

type DataKey = keyof DataRow; // Type representing the keys of DataRow

type Alignment = 'inherit' | 'left' | 'center' | 'right' | 'justify'; // Alignment options for table cells

// Define the structure for table header cells
interface HeadCell {
  id: DataKey;
  label: string;
  align?: Alignment;
}

// Define the table headers
const headCells: HeadCell[] = [
  { id: 'customerName', label: 'Customer Name', align: 'left' },
  { id: 'numberOfDevicesInGeotab', label: 'Number of Devices in Geotab', align: 'right' },
  { id: 'numberOfActiveDevices', label: 'Number of Active Devices', align: 'right' },
  { id: 'numberOfNotActiveDevices', label: 'Not Active Devices', align: 'right' },
  { id: 'numOfDevicesInMyAdmin', label: 'Number of Devices in MyAdmin', align: 'right' },
  { id: 'percentageOfTotalDevicesInstalled', label: '% of Total Devices Installed', align: 'right' },
];

const DeviceStatusTable: React.FC = () => {
  // State for sorting order and field
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<DataKey>('customerName');

  // State for search query
  const [searchQuery, setSearchQuery] = useState('');

  // State for selected customer details
  const [selectedCustomer, setSelectedCustomer] = useState<DataRow | null>(null);

  // State for the list of customers
  const [customers, setCustomers] = useState<DataRow[]>([]);

  // State for modal visibility
  const [isModalOpen, setIsModalOpen] = useState(false);

  // State for highlighting hovered rows
  const [hoveredRow, setHoveredRow] = useState<string | null>(null);

  // Fetch customers when the component is mounted
  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/customers`);
        const transformedData: DataRow[] = response.data.map((customer: any) => ({
          id: customer.id.toString(),
          customerName: customer.name,
          numberOfDevicesInGeotab: customer.num_devices_in_geotab || 0,
          numberOfActiveDevices: customer.num_active_devices || 0,
          numberOfNotActiveDevices: customer.num_inactive_devices || 0,
          numOfDevicesInMyAdmin: customer.num_devices_in_myadmin || 0,
          percentageOfTotalDevicesInstalled: customer.percent_devices_installed
            ? `${parseFloat(customer.percent_devices_installed).toFixed(2)}%`
            : 'N/A',
          comments: customer.comments || '',
        }));
        setCustomers(transformedData);
      } catch (error) {
        console.error('Error fetching customers:', error);
      }
    };

    fetchCustomers();
  }, []);

  // Update customer comments
  const updateCustomerComments = async (customerId: string, newComments: string) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/customers/${customerId}/comments`, { comments: newComments });
      setCustomers((prevCustomers) =>
        prevCustomers.map((customer) =>
          customer.id === customerId ? { ...customer, comments: newComments } : customer
        )
      );
    } catch (error) {
      console.error('Error updating comments:', error);
    }
  };

  // Handle sorting when a column header is clicked
  const handleRequestSort = (property: DataKey) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Prepare data for Excel export
  const excelData = customers.map((customer) => ({
    'Customer Name': customer.customerName,
    'Number of Devices in Geotab': customer.numberOfDevicesInGeotab,
    'Number of Active Devices': customer.numberOfActiveDevices,
    'Not Active Devices': customer.numberOfNotActiveDevices,
    'Number of Devices in MyAdmin': customer.numOfDevicesInMyAdmin,
    '% of Total Devices Installed': customer.percentageOfTotalDevicesInstalled,
    Comments: customer.comments,
  }));

  // Filter customers based on search query
  const filteredRows = React.useMemo(() => {
    return customers.filter((customer) =>
      customer.customerName.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [customers, searchQuery]);

  // Sort filtered rows based on selected order and field
  const sortedRows = React.useMemo(() => {
    return [...filteredRows].sort(getComparator(order, orderBy));
  }, [filteredRows, order, orderBy]);

  return (
    <Container maxWidth="xl">
      <Toolbar />
      {/* Search and Export Controls */}
      <Grid container alignItems="center" justifyContent="space-between" marginBottom={2}>
        <Grid>
          <TextField
            label="Search by Customer Name"
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{ width: '250px' }}
          />
        </Grid>
        <Grid>
          <ExcelDownloadButton data={excelData} filename="device_status.xlsx" />
        </Grid>
      </Grid>

      {/* Table Container */}
      <TableContainer component={Paper} sx={{ maxHeight: '500px', overflowY: 'auto' }}>
        <Table stickyHeader aria-label="device-status-table">
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell key={headCell.id} align={headCell.align}>
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={() => handleRequestSort(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRows.length > 0 ? (
              sortedRows.map((row) => (
                <TableRow
                  key={row.id}
                  hover
                  sx={{ backgroundColor: hoveredRow === row.id ? 'rgba(0, 0, 0, 0.14)' : 'inherit' }}
                  onMouseEnter={() => setHoveredRow(row.id)}
                  onMouseLeave={() => setHoveredRow(null)}
                >
                  <TableCell
                    onClick={() => {
                      setSelectedCustomer(row);
                      setIsModalOpen(true);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {row.customerName}
                  </TableCell>
                  <TableCell align="right">{row.numberOfDevicesInGeotab}</TableCell>
                  <TableCell align="right">{row.numberOfActiveDevices}</TableCell>
                  <TableCell align="right">{row.numberOfNotActiveDevices}</TableCell>
                  <TableCell align="right">{row.numOfDevicesInMyAdmin}</TableCell>
                  <TableCell align="right">{row.percentageOfTotalDevicesInstalled}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No matching records found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Customer Details Modal */}
      {selectedCustomer && (
        <CustomerDetailsModal
          customer={selectedCustomer}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          updateComments={updateCustomerComments}
        />
      )}
    </Container>
  );
};

export default DeviceStatusTable;
