// ChartComponent.tsx
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import { format } from 'date-fns';
import { blue, green } from '../../types';
import { DataPoint } from '../../types';
import ExcelDownloadButton from '../../ExcelDownloadButton';

const colors = ['#8884d8', '#82ca9d', '#FF8042', '#8A2BE2', '#FF7F50'];

interface ChartComponentProps {
  data: DataPoint[];
  allCustomersData: Record<string, { name: string; data: DataPoint[] }>;
  selectedCustomersData: Record<string, { name: string; data: DataPoint[] }>;
  title: string;
  startDate: Date;
  endDate: Date;
}

// A single combined data point in the chart
interface CombinedDataPoint {
  date: number;
  collisionRisk?: number | null;
  benchmarkRisk?: number | null;
  bestInClassRisk?: number | null;
  [key: string]: number | null | undefined;
}

// For Recharts legend
type LegendType =
  | 'line'
  | 'square'
  | 'rect'
  | 'circle'
  | 'cross'
  | 'diamond'
  | 'star'
  | 'triangle'
  | 'wye'
  | 'none';

interface LegendPayload {
  value: string;
  type?: LegendType;
  color: string;
  id?: string;
}

interface PercentChangeInfo {
  percentChange: number | null;
  changeDirection: 'increase' | 'decrease' | null;
}

// A row in the final CSV:
interface CSVRow {
  date: string;
  [key: string]: number | string | null;
}

// Constants for line names
const HANOVER_FLEETS_NAME = 'Hanover Fleets';
const BENCHMARK_RISK_NAME = 'Benchmark Risk';
const BEST_IN_CLASS_RISK_NAME = 'Best in Class Risk';

// Optional descriptions for the tooltip on the legend
const lineDescriptions: Record<string, string> = {
  [HANOVER_FLEETS_NAME]: 'Average collision risk across all Hanover fleets.',
  [BENCHMARK_RISK_NAME]: 'Industry benchmark collision risk.',
  [BEST_IN_CLASS_RISK_NAME]: 'Collision risk for the top-performing fleets.',
};

const formatTickLabel = (date: number): string => {
  return format(new Date(date), 'MMM dd, yyyy');
};

const calculateTicks = (minDate: number, maxDate: number, maxTicks: number): number[] => {
  const ticks: number[] = [];
  const startTime = minDate;
  const endTime = maxDate;
  const dateRangeInDays = Math.ceil((endTime - startTime) / (1000 * 60 * 60 * 24)) + 1;

  if (dateRangeInDays <= maxTicks) {
    // If the entire range is smaller than maxTicks, just make a tick for every day
    for (let i = 0; i < dateRangeInDays; i++) {
      const tickTime = startTime + i * (1000 * 60 * 60 * 24);
      ticks.push(tickTime);
    }
  } else {
    const interval = (endTime - startTime) / (maxTicks - 1);
    for (let i = 0; i < maxTicks; i++) {
      const tickTime = startTime + i * interval;
      ticks.push(Math.round(tickTime));
    }
  }
  return ticks;
};

function mergeDataForChart(
  fleetData: DataPoint[],
  customersData: Record<string, { name: string; data: DataPoint[] }>
): CombinedDataPoint[] {
  const dateMap: Record<number, CombinedDataPoint> = {};

  fleetData.forEach((d) => {
    if (!dateMap[d.date]) dateMap[d.date] = { date: d.date };
    dateMap[d.date].collisionRisk = d.collisionRisk || null;
  });

  // 2. Add each customer’s data
  Object.entries(customersData).forEach(([custId, custInfo]) => {
    const dataKey = `customer_${custId}`;
    custInfo.data.forEach((pt) => {
      if (!dateMap[pt.date]) dateMap[pt.date] = { date: pt.date };
      dateMap[pt.date][dataKey] = pt.collisionRisk || null;
    });
  });

  // 3. Convert to array & sort
  const merged: CombinedDataPoint[] = Object.values(dateMap).sort((a, b) => a.date - b.date);
  return merged;
}
const ChartComponent: React.FC<ChartComponentProps> = ({
  data,
  allCustomersData,
  selectedCustomersData,
  title,
  startDate,
  endDate,
}) => {
  const [legendHeight, setLegendHeight] = useState(0);

  // Which lines are visible in the chart
  const [visibleLines, setVisibleLines] = useState<Record<string, boolean>>({
    [HANOVER_FLEETS_NAME]: true,
    [BEST_IN_CLASS_RISK_NAME]: true,
  });

  // Prepare data for Excel download
  const prepareExcelData = useCallback(() => {
    const excelData: any[] = [];
    data.forEach((d) => {
      const row: Record<string, any> = {
        Date: format(new Date(d.date), 'yyyy-MM-dd'),
        'Collision Risk': d.collisionRisk ?? '',
        'Benchmark Risk': d.benchmarkRisk ?? '',
      };
      Object.entries(allCustomersData).forEach(([id, customer]) => {
        const customerData = customer.data.find((dp) => dp.date === d.date);
        row[customer.name] = customerData?.collisionRisk ?? '';
      });
      excelData.push(row);
    });
    return excelData;
  }, [data, allCustomersData]);

  const excelData = useMemo(() => prepareExcelData(), [prepareExcelData]);
  const [percentChanges, setPercentChanges] = useState<Record<string, PercentChangeInfo>>({});

  // Each time selectedCustomersData changes, ensure we add them to “visibleLines”
  useEffect(() => {
    setVisibleLines((prevState) => {
      const newVisible = { ...prevState };
      // always keep Hanover Fleets & Best in Class lines
      if (newVisible[HANOVER_FLEETS_NAME] === undefined) {
        newVisible[HANOVER_FLEETS_NAME] = true;
      }
      if (newVisible[BEST_IN_CLASS_RISK_NAME] === undefined) {
        newVisible[BEST_IN_CLASS_RISK_NAME] = true;
      }

      // Add in each new customer line if not already present
      Object.entries(selectedCustomersData).forEach(([_, customerInfo]) => {
        if (newVisible[customerInfo.name] === undefined) {
          newVisible[customerInfo.name] = true;
        }
      });
      return newVisible;
    });
  }, [selectedCustomersData]);

  // Clicking a legend item toggles that line’s visibility
  const handleLegendClick = (legendItem: { value: string }) => {
    const { value } = legendItem;
    setVisibleLines((prev) => ({
      ...prev,
      [value]: !prev[value],
    }));
  };

  // === 1) BUILD THE “COMBINED” TIME-SERIES DATA ===
  const [combinedChartData, setCombinedChartData] = useState<CombinedDataPoint[]>([]);

  useEffect(() => {
    const dateMap: Record<number, CombinedDataPoint> = {};

    // Start with the “Hanover fleets” data
    if (data && data.length > 0) {
      data.forEach((d) => {
        dateMap[d.date] = {
          ...dateMap[d.date],
          date: d.date,
          // Set null if 0, or if not present
          collisionRisk: d.collisionRisk === 0 ? null : d.collisionRisk,
          benchmarkRisk: d.benchmarkRisk === 0 ? null : d.benchmarkRisk,
        };
      });
    }

    // Now incorporate each selected customer’s data
    Object.entries(selectedCustomersData).forEach(([custId, custInfo]) => {
      const dataKey = `customer_${custId}`;
      custInfo.data.forEach((pt) => {
        if (!dateMap[pt.date]) {
          dateMap[pt.date] = { date: pt.date };
        }
        dateMap[pt.date][dataKey] = pt.collisionRisk ?? null;
      });
    });

    // Convert map -> array, remove dates that have no data at all, then sort
    const combinedArray: CombinedDataPoint[] = Object.values(dateMap)
      .filter((dp) => {
        return Object.keys(dp).some((key) => key !== 'date' && dp[key] != null);
      })
      .sort((a, b) => a.date - b.date);

    setCombinedChartData(combinedArray);
  }, [data, selectedCustomersData]);


  // === 2) DETERMINE THE CHART’S X-AXIS TICKS ===
  const minDate = combinedChartData.length > 0
    ? Math.min(...combinedChartData.map((d) => d.date))
    : startDate.getTime();
  const maxDate = combinedChartData.length > 0
    ? Math.max(...combinedChartData.map((d) => d.date))
    : endDate.getTime();

  const dataPointCount = combinedChartData.length;
  const maxTicks = dataPointCount <= 10 ? dataPointCount : 6;
  const ticks = calculateTicks(minDate, maxDate, maxTicks);

  const [combinedCsvData, setCombinedCsvData] = useState<CombinedDataPoint[]>([]);
  useEffect(() => {
    // If the user selected none => use allCustomersData
    // else => use selectedCustomersData
    const hasSelections = Object.keys(selectedCustomersData).length > 0;

    const csvSource = hasSelections ? selectedCustomersData : allCustomersData;
    const newCsvData: CombinedDataPoint[] = mergeDataForChart(data, csvSource);
    setCombinedCsvData(newCsvData);
  }, [data, allCustomersData, selectedCustomersData]);
  // === 3) BUILD THE LEGEND PAYLOAD (for Recharts) ===
  const getColor = useCallback((index: number) => colors[index % colors.length], []);
  const legendPayload: LegendPayload[] = useMemo(() => {
    let index = 0;
    const payload: LegendPayload[] = [
      {
        value: HANOVER_FLEETS_NAME,
        type: 'line' as LegendType,
        color: blue,
      },
      {
        value: BEST_IN_CLASS_RISK_NAME,
        type: 'line' as LegendType,
        color: green,
      },
    ];

    // Add each selected customer
    Object.entries(selectedCustomersData).forEach(([_, custInfo]) => {
      payload.push({
        value: custInfo.name,
        type: 'line' as LegendType,
        color: getColor(index),
      });
      index++;
    });

    return payload;
  }, [selectedCustomersData, getColor]);

  // For CHART lines & tooltips
  const customerDataKeys = useMemo(() => {
    const map: Record<string, string> = {};
    Object.entries(selectedCustomersData).forEach(([customerId, info]) => {
      map[`customer_${customerId}`] = info.name;
    });
    return map;
  }, [selectedCustomersData]);

  // 1) Decide if we’re using selectedCustomers or allCustomers for CSV
  const hasSelections = Object.keys(selectedCustomersData).length > 0;
  const csvSource = hasSelections ? selectedCustomersData : allCustomersData;

  // 2) Build the map from that set
  const csvCustomerDataKeys = useMemo(() => {
    const map: Record<string, string> = {};
    Object.entries(csvSource).forEach(([customerId, info]) => {
      map[`customer_${customerId}`] = info.name;
    });
    return map;
  }, [csvSource]);

  const [csvData, setCsvData] = useState<CSVRow[]>([]);

  useEffect(() => {
    // Convert each CombinedDataPoint into a CSVRow
    const csvRows: CSVRow[] = combinedCsvData.map((dp) => {
      const formattedDate = format(new Date(dp.date), 'yyyy-MM-dd');

      // Start row with date
      const row: CSVRow = { date: formattedDate };

      // Hanover fleets
      if (dp.collisionRisk !== undefined) {
        row[HANOVER_FLEETS_NAME] = dp.collisionRisk;
      }

      // Benchmark
      if (dp.benchmarkRisk !== undefined) {
        row[BENCHMARK_RISK_NAME] = dp.benchmarkRisk;
      }

      // Best in Class
      if (dp.bestInClassRisk !== undefined) {
        row[BEST_IN_CLASS_RISK_NAME] = dp.bestInClassRisk;
      }

      // Each selected customer
      Object.keys(csvCustomerDataKeys).forEach((dataKey) => {
        if (dp[dataKey] !== undefined) {
          const custName = csvCustomerDataKeys[dataKey];
          row[custName] = dp[dataKey] ?? null;
        }
      });

      return row;
    });

    setCsvData(csvRows);
  }, [combinedCsvData, csvCustomerDataKeys]);


  // === PERCENT CHANGE CALCULATIONS ===
  useEffect(() => {
    // The “lines” we are drawing:
    const allLines = [
      { dataKey: 'collisionRisk', name: HANOVER_FLEETS_NAME },
      { dataKey: 'bestInClassRisk', name: BEST_IN_CLASS_RISK_NAME },
      ...Object.entries(selectedCustomersData).map(([id, info], idx) => ({
        dataKey: `customer_${id}`,
        name: info.name,
      })),
    ];

    const newPercentChanges: Record<string, PercentChangeInfo> = {};

    allLines.forEach(({ dataKey, name }) => {
      const values = combinedChartData
        .filter((d) => d[dataKey] != null)
        .map((d) => d[dataKey] as number);

      if (values.length >= 2) {
        const firstVal = values[0];
        const lastVal = values[values.length - 1];
        const change = lastVal - firstVal;
        const pct = (change / firstVal) * 100;

        let dir: 'increase' | 'decrease' | null = null;
        if (pct > 0) dir = 'increase';
        else if (pct < 0) dir = 'decrease';

        newPercentChanges[name] = {
          percentChange: Math.abs(pct),
          changeDirection: dir,
        };
      } else {
        newPercentChanges[name] = { percentChange: null, changeDirection: null };
      }
    });

    setPercentChanges(newPercentChanges);
  }, [combinedChartData, selectedCustomersData]);

  return (
    <Box sx={{ marginTop: 4 }}>
      {/* Title & CSV Download */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 2,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6" gutterBottom sx={{ marginRight: 1 }}>
            {title}
            <Tooltip
              title="Trending probability of a collision occurring in the next 100,000 miles, based on your selected date range. Updated daily with a 2-day delay."
              arrow
              placement="top"
            >
              <InfoOutlinedIcon color="action" fontSize="inherit" />
            </Tooltip>
          </Typography>
        </Box>

        {/* Excel Download Button */}
        <ExcelDownloadButton data={excelData} filename="collision_risk_data.xlsx" />
      </Box>

      {/* The actual chart */}
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={combinedChartData}
          margin={{ top: legendHeight, right: 30, left: 20, bottom: 80 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            type="number"
            domain={[minDate, maxDate]}
            ticks={ticks}
            tickFormatter={(date) => formatTickLabel(date)}
            angle={-45}
            textAnchor="end"
            height={70}
            scale="time"
          />
          <YAxis
            tickFormatter={(value) => `${value}%`}
            label={{
              value: 'Avg Collision Risk (per 100k miles)',
              angle: -90,
              position: 'insideLeft',
              style: { textAnchor: 'middle' },
            }}
          />
          <RechartsTooltip
            labelFormatter={(label) => format(new Date(label), 'PPP')}
            formatter={(value: any, name: any, props: any) => {
              // Re-map dataKey to display name
              const dataKey = props.dataKey;
              let displayName = customerDataKeys[dataKey] || name;
              if (dataKey === 'collisionRisk') displayName = HANOVER_FLEETS_NAME;
              if (dataKey === 'bestInClassRisk') displayName = BEST_IN_CLASS_RISK_NAME;

              if (value == null) {
                return ['No data', displayName];
              }

              // Show +/- in tooltip
              const pc = percentChanges[displayName];
              const pctText =
                pc && pc.percentChange != null
                  ? ` (${pc.changeDirection === 'increase' ? '+' : '-'}${pc.percentChange.toFixed(1)}%)`
                  : '';

              return [`${value}%`, `${displayName}${pctText}`];
            }}
            contentStyle={{ backgroundColor: '#f5f5f5' }}
          />
          <Legend
            wrapperStyle={{ width: '90%', paddingBottom: 20 }}
            verticalAlign="top"
            align="center"
            onClick={handleLegendClick}
            payload={legendPayload}
            onBBoxUpdate={(box) => {
              setLegendHeight((box?.height ?? 0) + 20);
            }}
            formatter={(value) => {
              const active = visibleLines[value];
              const hasDescription = lineDescriptions.hasOwnProperty(value);

              const pc = percentChanges[value];
              const pctText =
                pc && pc.percentChange != null
                  ? ` (${pc.changeDirection === 'increase' ? '+' : '-'}${pc.percentChange.toFixed(1)}%)`
                  : '';

              const legendItem = (
                <span
                  style={{
                    textDecoration: active ? 'none' : 'line-through',
                    color: active ? '#000' : '#AAA',
                    cursor: 'pointer',
                  }}
                >
                  {value}
                  {pctText}
                </span>
              );
              return hasDescription ? (
                <Tooltip title={lineDescriptions[value]} placement="top" arrow>
                  {legendItem}
                </Tooltip>
              ) : (
                legendItem
              );
            }}
          />

          {/* Hanover Fleets */}
          <Line
            type="monotone"
            dataKey="collisionRisk"
            stroke={blue}
            strokeWidth={2}
            dot={false}
            name={HANOVER_FLEETS_NAME}
            hide={!visibleLines[HANOVER_FLEETS_NAME]}
            connectNulls={true}
          />

          {/* Each selected customer */}
          {Object.entries(selectedCustomersData).map(([customerId, custInfo], index) => {
            const dataKey = `customer_${customerId}`;
            return (
              <Line
                key={dataKey}
                type="monotone"
                dataKey={dataKey}
                stroke={getColor(index)}
                strokeWidth={2}
                dot={false}
                name={custInfo.name}
                hide={!visibleLines[custInfo.name]}
                connectNulls={true}
              />
            );
          })}
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default React.memo(ChartComponent);
