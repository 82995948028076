// types.tsx
export interface FleetData {
  id: string;
  fleetName: string;
  collisionRisk: number | null;
  benchmarkRisk: number;
  riskRelativeToHanover: number | null;
  engagementScore: number;
}

export interface DataPoint {
  date: number;
  collisionRisk: number;
  benchmarkRisk: number | null;
}

export interface CollisionRisk {
  id: string;
  customer_id: string;
  customer?: Customer;
  date: Date;
  risk_percent: Number;
}

export interface Comments {
  id: string;
  customer_id: string;
  comment_text: string;
  created_by: string;
  created_at: string;
  updated_at: string;
}

export interface Region {
  id: string;
  name: string;
}

export interface Customer {
  id: string;
  name: string;
  region_id: string;
  region?: Region | null;
  collisionRisk: number | null;
  data: DataPoint[];
  engagementScore: number;
  numDevicesInGeotab: number;
  numActiveDevices: number;
  numInactiveDevices: number;
  numDevicesInMyadmin: number;
  percentDevicesInstalled: number;
  comments?: string;
}

export interface CustomerEngagement {
  id: string;
  customer_id: string;
  customer?: Customer;
  engagement_score: Number;
}

export interface Device {
  id: string;
  serialNumber: string;
  customerId: string;
  customer?: Customer;
  isInstalled: Boolean;
  shipmentDate: string;
  ratePlan: string;
  daysSinceShipment?: number;
  createdAt: string;
}

export interface Permissions {
  id: string;
  role_id: string;
  role?: Role;
  permission_name: string;
}

export interface Region {
  id: string;
  name: string;
}

enum RoleEnum { 'SUPERADMIN', 'ADMIN', 'MEMBER' }
export interface Role {
  id: string;
  role: RoleEnum
}

export interface ServiceAccount {
  id: string;
  username: string;
  password: string;
  db_name: string;
  customer_id: string;
  customer?: Customer;
}

export interface User {
  id: string;
  email: string;
  role_id: number;
  region_id?: string;
  region?: string;
  auth0_sub?: string;
}


export const red = '#de3030';
export const blue = '#0b6bb4';
export const green = '#337343';
export const orange = '#DA7C3A';
